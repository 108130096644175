import type { FC } from 'react';

import { Spinner } from '@nextui-org/react';

import { useStore } from '~/store';

const PageLoader: FC = () => {
  const isLoading = useStore((state) => state.isLoading);
  const dimmed = useStore((state) => state.dimmed);

  return (
    <div
      className={`${isLoading ? '' : 'hidden'} fixed top-0 left-0 w-full h-full ${dimmed ? 'bg-black bg-opacity-20' : ''} flex justify-center items-center z-50`}
    >
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Spinner color="success" size="lg" />
      </div>
    </div>
  );
};

export default PageLoader;
